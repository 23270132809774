
.card {
  border: 1px solid black;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
}

.card-details {
  min-width: 350px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-details .header {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
  min-width: 200px;
  text-align: center;
}

.card-details .button {
  display: block;
  margin-top: 20px;
}