@import url(styles/button.css);
@import url(styles/card.css);
@import url(styles/showGame.css);
@import url(styles/ShowTeamMember.scss);

/* New colors
  logo blue #3b3e8c
  darker blue #3e418e
  yellow #f7c73e
*/
.container {
  display: flex;
  /* max-width: 400px; */
  flex: 1;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.button-bar {
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
  width: 150px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.text-center {
  text-align: center;
}

.captnTextInput,
.captnPicker {
  margin: 5px;
  height: 25px;
}

.captnTextArea {
  width: 100%;
  height: 80px;
}

.teamDetailsRoute h4 {
  margin-top: 40px;
}

.gameDate {
  width: 60px;
  font-size: 14px;
}

.Toastify__toast-container {
  white-space: pre-line;
  width: 400px;
}

.pointer {
  cursor: pointer;
}


p.small {
  font-size: .8em;
}

.StripeElement form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.StripeElement button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.StripeElement button:hover {
  filter: contrast(115%);
}

.StripeElement button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.schedule-area {
  width: 50%;

  table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    tr {
      &:hover {
        background-color: #D6EEEE;
      }

      border-bottom: 1px solid #ddd;
      cursor: pointer;
    }
  }
}

// .schedule-table {
// }

// .schedule-table tr:hover {
//   background-color: #D6EEEE;
// }

// .schedule-table tr {
//   border-bottom: 1px solid #ddd;
//   cursor: pointer;
// }