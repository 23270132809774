.button {
  border-radius: 12px;
  border-style: none;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 16px;
  margin: 10px;
  cursor: pointer;
}
.button.primary {
  background-color: rgb(23, 102, 221);
  color: white;
}
.button.destructive {
  background-color: rgb(233, 39, 39);
  color: white;
}
.button.link {
  border: 1px solid black;
  color: black;
  font-size: 11px;
}
.button:disabled {
  background-color: gray;
  cursor: default;
}
/* .button:hover,
.button:focus {
  background-color: #F082AC;
} */