.showGame {
  min-width: 350px;
  margin-bottom: 20px;
}

.showGame .teamName {
  font-weight: 600;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.showGame .button {
  margin: 0px;
  padding: 10px;
}